import React from 'react';
import Header from './Header';
import Carousel from './Carousel';
import Content from './Content';


const App = () => {
  return (
  <>
    <Header />
    <main className="main">
      <Carousel />
      <Content />
    </main>
  </>
  );
}

export default App;

import React from 'react';
import { NavLink } from 'react-router-dom';

const Header = () => {
return (
  <header className="header">
    <h1 className="header__logo">ЦЕНТР СТРАХОВАНИЯ<sup><sup>&reg;</sup></sup></h1>
    <ul className="header__menu">
      <li className="header__menu-item">
        <NavLink to="/kasko" activeClassName="currentPage" className="header__menu-link">КАСКО</NavLink>
        <span className="header__menu-splitter"> &#149; </span>
      </li>
      <li className="header__menu-item">
        <NavLink to="/osago" activeClassName="currentPage" className="header__menu-link">ОСАГО</NavLink>
        <span className="header__menu-splitter">&#149;</span>
      </li>
      <li className="header__menu-item">
        <NavLink to="/zk" activeClassName="currentPage" className="header__menu-link">ЗЕЛЕНАЯ КАРТА</NavLink>
      </li>
    </ul>
    <div className="header__contacts">
      <div className="header__phone">+7 (812) 438-38-01</div>
      <div className="header__whatsapp">
        <a href="https://wa.me/79118181518" target="_blank" rel="noreferrer" alt="whatsapp">задать вопрос</a> в Whatsapp</div>
    </div>
  </header>
  );
}

export default Header;

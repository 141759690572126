import React from 'react';
import { NavLink } from 'react-router-dom';


const NavBullets = () => {
  return (
      <div className="carousel__nav">
        <NavLink to="/kasko" className="carousel__nav-bullet" activeClassName="active_bullet" />
        <NavLink to="/osago" className="carousel__nav-bullet" activeClassName="active_bullet" />
        <NavLink to="/zk" className="carousel__nav-bullet" activeClassName="active_bullet" />
      </div>
    );
}

export default NavBullets;

import React from 'react';

const Content = () => {
  return (
  <section className="content">

  </section>
  );
}

export default Content;

import React from 'react'
import { useTransition, animated } from 'react-spring'
import { useLocation, Route, Switch } from 'react-router-dom';
import NavBullets from './NavBullets';

const Carousel = () => {
  const location = useLocation()
  const transitions = useTransition(location, location => location.pathname, {
    from: { opacity: 0, transform: 'translate(100%,0)' },
    enter: { opacity: 1, transform: 'translate(0,0)' },
    leave: { opacity: 0, transform: 'translate(-50%,0)' },
  })

  return transitions.map(({ item: location, props, key }) => (
  <section className="carousel">
    <animated.div key={ key } style={ props }>
      <Switch location={ location }>
        <Route exact path="/" component={ FirstSlide } />
        <Route path="/kasko" component={ FirstSlide } />
        <Route path="/osago" component={ SecondSlide } />
        <Route path="/zk" component={ ThirdSlide } />
      </Switch>
    </animated.div>
  </section>
  ))
}


const FirstSlide = () => (
  <div className="carousel__slide first_slide">
    <NavBullets />
    <div className="carousel__content">
      <span className="carousel__text">БЫСТРЫЙ</span>
      <span className="carousel__accent">
        РАСЧЕТ КАСКО
      </span>
    </div>
  </div>
)

const SecondSlide = () => (
  <div className="carousel__slide second_slide">
    <NavBullets />
    <div className="carousel__content">
      <span className="carousel__text">ОФОРМИТЬ</span>
      <span className="carousel__accent">ОСАГО ОНЛАЙН</span>
    </div>
  </div>
)

const ThirdSlide = () => (
  <div className="carousel__slide third_slide">
    <NavBullets />
    <div className="carousel__content">
      <span className="carousel__text">СТОИМОСТЬ</span>
      <span className="carousel__accent">ЗЕЛЕНОЙ КАРТЫ</span>
    </div>
  </div>
)

export default Carousel;
